import React, { useContext } from "react";
import { StyledButton, StyledCta } from "./style.jsx";
import { Link } from "gatsby";
import { DataContext } from "../../contexts/DataContext.jsx";

export default ({
  type = "button",
  label,
  children,
  handleOnClick,
  as,
  theme,
  noPadding,
  noLine,
  smaller,
  revert,
  isHidden,
  className,
  dataitemid,
  dataitemprice,
  dataitemurl,
  dataitemname,
  dataitemimage,
  dataitemweight,
  dataitemdescription,
  dataitemcustom1name,
  dataitemcustom1options,
  noAnimation,
  to,
  useBlankTarget,
  disabled,
}) => {
  const { i18nLink } = useContext(DataContext);

  if (as) {
    return (
      <StyledCta
        theme={theme}
        as={as}
        noPadding={noPadding}
        noLine={noLine}
        smaller={smaller}
        onClick={handleOnClick}
        revert={revert}
        className={className}
        data-item-id={dataitemid}
        data-item-price={dataitemprice}
        data-item-url={dataitemurl}
        data-item-image={dataitemimage}
        data-item-name={dataitemname}
        data-item-weight={dataitemweight}
        data-item-description={dataitemdescription}
        data-item-custom1-name={dataitemcustom1name}
        data-item-custom1-options={dataitemcustom1options}
        noAnimation={noAnimation}
        isDisabled={disabled}
      >
        {to ? (
          to.indexOf("http") === 0 ? (
            <a href={i18nLink(to)} target={useBlankTarget}>{children}</a>
          ) : (
            <Link to={i18nLink(to)}>{children}</Link>
          )
        ) : (
          children
        )}
      </StyledCta>
    );
  }
  return (
    <StyledButton
      type={type}
      onClick={handleOnClick}
      isHidden={isHidden}
      noAnimation={noAnimation}
    >
      {label || children}
    </StyledButton>
  );
};
