import React, { useContext } from "react";
import {
  StyledProductCard, 
  StyledShippingLabel, 
  StyledDivider,
  StyledPriceValues,
  StyledProductType, StyledName, StyledInitialPrice, StyledFinalPrice, StyledPriceParagraph,
  StyledDiscountPercentage,
  StyledCardBody,
  StyledQuantityLeftContainer, StyledQuantityLeftText, StyledQuantityLeft,
  StyledShippingTo, StyledImage, StyledShippingToContainer, StyledSaveCircle, StyledSaveCircleText,
  StyledFlag,
  StyledShippingLabels, StyledShippingLabelSmall,
  StyledTopLeftMessages,
  StyledPriceSubtitle,
  StyledProductQuantity,
  StyledImageLabels,
  StyledRedRows, StyledRedRow,
  StyledFeatures, StyledFeaturesIcon, StyledFeatureList, StyledFeature,
} from "./style";
import { DataContext } from "../../contexts/DataContext";
import Button from "../Button/Button";
import Icon, { icons } from "../Icon/Icon";
import { Colors } from "../../commons/Theme";
import { LayoutContext } from "../../contexts/LayoutContext";
import { useLocation } from "@reach/router";

// eslint-disable-next-line no-unused-vars

export default ({
  productId,
  name,
  url,
  image,
  sections,
  shippingLabel,
  shippingLabels,
  productType,
  finalPrice,
  initialPrice,
  discountPercentage,
  shippingMonth,
  quantityLeft,
  shippingTo,
  icon,
  saveCircleText, saveCircleTop, saveCircleRight,
  priceDetails,
  priceSubtitle,
  productQuantity,
  topLeftMessages,
  imageLabels,
  features,
  imageNote,
  weight,
  cartDescription,
  cartCustom1Name,
  cartCustom1Options,
}) => {

  const getCartItemName = (name, productQuantity) => {
    const suffix = productQuantity && ["x 1", "x1"].indexOf(productQuantity.toLowerCase()) ? ` ${productQuantity}` : "";

    return `${name}${suffix}`;
  };
  const { labels, selectedSectionId } = useContext(DataContext);
  const { setModalContent } = useContext(LayoutContext);
  const showCircle = saveCircleText && saveCircleTop && saveCircleRight;
  const hideShippingDiv = (!shippingLabel || shippingLabel === " ");
  const location = useLocation();
  const showProductCard = sections && sections.indexOf(location.pathname == "/app/" ? "hive-tech" : selectedSectionId) > -1;
  const cartItemName = getCartItemName(name, productQuantity);
  const outOfStock = quantityLeft[0] == "0";

  return showProductCard ? (
    <StyledProductCard id={`productCard-${productId}`}>
        
      {
        shippingLabels && shippingLabels.length > 0 
          ?          <StyledShippingLabels>
            {shippingLabels.map((label, index) => (
              <StyledShippingLabelSmall key={index} >{label.text}</StyledShippingLabelSmall>
            ))}
          </StyledShippingLabels>
          :           <StyledShippingLabel empty={hideShippingDiv}>{shippingLabel}</StyledShippingLabel>
      }

      { topLeftMessages && topLeftMessages.items
          && <StyledTopLeftMessages bold={topLeftMessages.bold}>
            {topLeftMessages.items.map((item, index) => (
              <p key={index} >{item.text}</p>
            ))}
          </StyledTopLeftMessages>
      }

      { productQuantity && <StyledProductQuantity>{productQuantity}</StyledProductQuantity> }

      <StyledImage src={image} alt={name} loading="lazy" />
      {showCircle && (
        <StyledSaveCircle circleTop={saveCircleTop} circleRight={saveCircleRight}>
          <StyledSaveCircleText
            dangerouslySetInnerHTML={{ __html: saveCircleText }}
          />
        </StyledSaveCircle>
      )}
      { imageLabels && imageLabels.items
          && <StyledImageLabels bold={imageLabels.bold}>
            {imageLabels.items.map((item, index) => (
              <p key={index} >{item.text}</p>
            ))}
          </StyledImageLabels>
      }

      <StyledDivider imageNote={imageNote}/>

      <StyledCardBody>
        <StyledProductType>{ productType }</StyledProductType>
        <StyledName>
          { name }
          {features 
              && <StyledFeatures>
                <StyledFeaturesIcon
                  onClick={() => {setModalContent(
                    <StyledFeatureList>
                      <h5 className="featureListTitle">{labels.FEATURE_LIST}</h5>
                      {features.weight && <StyledFeature><span className="label">{labels.WEIGHT}:</span> {features.weight}</StyledFeature>}
                      {features.features && <StyledFeature><span className="label">{labels.FEATURES}:</span> {features.features}</StyledFeature>}
                      {features.includedPlan && <StyledFeature><span className="label">{labels.INCLUDED_PLAN}:</span> {features.includedPlan}</StyledFeature>}
                      {features.shippingCosts && <StyledFeature><span className="label">{labels.SHIPPING_COSTS}:</span> {features.shippingCosts}</StyledFeature>}
                      {features.dimensions && <StyledFeature><span className="label">{labels.DIMENSIONS}:</span> {features.dimensions}</StyledFeature>}
                      {features.paymentMethods && <StyledFeature><span className="label">{labels.PAYMENT_METHODS}:</span> {features.paymentMethods}</StyledFeature>}
                    </StyledFeatureList>,
                  );}} 
                >?</StyledFeaturesIcon>
              </StyledFeatures>
          }
        </StyledName>

        <StyledPriceParagraph>
          <StyledPriceValues>
            <StyledFinalPrice>{ finalPrice } &euro;</StyledFinalPrice>
            <StyledRedRows>
              <StyledRedRow>
                <StyledInitialPrice>{ initialPrice }</StyledInitialPrice>
                <StyledDiscountPercentage>{ discountPercentage }</StyledDiscountPercentage>
              </StyledRedRow>
              { priceDetails 
                && <StyledRedRow dangerouslySetInnerHTML={{ __html: priceDetails }}>
                </StyledRedRow>
              }
            </StyledRedRows>
          </StyledPriceValues>
          {priceSubtitle && <StyledPriceSubtitle dangerouslySetInnerHTML={{ __html: priceSubtitle }}/>}
        </StyledPriceParagraph>

        <StyledQuantityLeftContainer>
          <StyledQuantityLeftText>{ labels.QUANTITY_LEFT_ONLY }</StyledQuantityLeftText>
          <StyledQuantityLeft> { quantityLeft } </StyledQuantityLeft>
          <StyledQuantityLeftText>{ labels.QUANTITY_LEFT }</StyledQuantityLeftText>
        </StyledQuantityLeftContainer>
        

        {outOfStock && (
          <Button as="div"
            theme="yellow"
            disabled={outOfStock}
          >
            {labels.BUY}{" "}
            <Icon icon={icons.arrowRight} color={Colors.white} />
          </Button>
        )}

        {!outOfStock && (
          <Button as="div"
            theme="yellow"
            className="snipcart-add-item"
            dataitemid={productId}
            dataitemurl={url}
            dataitemimage={image}
            dataitemprice={finalPrice}
            dataitemname={cartItemName}
            dataitemweight={weight}
            dataitemdescription={cartDescription}
            dataitemcustom1name={cartCustom1Name}
            dataitemcustom1options={cartCustom1Options}
          >
            {labels.BUY}{" "}
            <Icon icon={icons.arrowRight} color={Colors.white} />
          </Button>
        )}
          
      </StyledCardBody>
    </StyledProductCard>
  ) : null;
};
