import styled, { css } from "styled-components";
import { BP, Colors, Rem } from "../../commons/Theme";

export const StyledProductCard = styled.div`
  width: 100%;
  min-width: ${Rem(320)};
  max-width: ${Rem(340)};
  border: 1px solid black;
  background-color: rgba(255, 255, 255, 1);
  position: relative;
  overflow: hidden;
  border-radius: 20px;
`;

export const StyledShippingLabels = styled.div`
  display: inline-block;
  position: relative;
  height: ${Rem(50)};
  left: -10px;
  top: -12px;

  background: ${Colors.yellow};
  border-radius: 14px;
  font-size: ${Rem(20)};
  padding-top: ${Rem(14)};
  padding-left: ${Rem(20)};
  padding-right: ${Rem(20)};
  color: white;
`;

export const StyledShippingLabelSmall = styled.p`
  font-size: ${Rem(16)};
`;

export const StyledShippingLabel = styled.div`
  display: inline-block;
  position: relative;
  height: ${Rem(50)};
  left: -10px;
  top: -12px;

  background: ${(props) => props.empty ? Colors.white : Colors.yellow};
  border-radius: 14px;
  font-size: ${Rem(20)};
  padding-top: ${Rem(20)};
  padding-left: ${Rem(20)};
  padding-right: ${Rem(20)};
  color: white;
`;

export const StyledTopLeftMessages = styled.div`
  position: absolute;
  top: ${Rem(30)};
  left: ${Rem(20)};
  font-size: ${Rem(16)};
  font-weight: 200;

  ${(props) => props.bold && css`
    top: ${Rem(10)};
    left: ${Rem(12)};
    font-size: ${Rem(18)};
    font-weight: unset;
  `}
`;

export const StyledImageLabels = styled.div`
  position: absolute;
  top: ${Rem(70)};
  right: ${Rem(30)};
  font-size: ${Rem(12)};
  font-weight: 200;

  ${(props) => props.bold && css`
    font-size: ${Rem(18)};
    font-weight: unset;
    color: blue;
  `}
`;

export const StyledProductQuantity = styled.div`
  position: absolute;
  top: ${Rem(12)};
  right: ${Rem(20)};
`;

export const StyledImage = styled.img`
  width: 100%;
  height: ${Rem(210)};
`;

export const StyledDivider = styled.div`
  margin-left: ${Rem(15)};
  margin-right: ${Rem(15)};
  margin-bottom: 0;
  width: calc(100% - ${Rem(15)} - ${Rem(15)}); 
  height: ${Rem(2)};
  border: 0;
  background: ${Colors.yellow};
  position: relative;

  ${(props) => props.imageNote && css`
    &:before {
      content: '${props => props.imageNote}';
      position: relative;
      top: ${Rem(-20)};
      height: ${Rem(40)};
      font-weight: 200;
    }
  `}
  
`;

export const StyledCardBody = styled.div`
  font-size: ${Rem(24)};
  line-height: ${Rem(30)};
  font-weight: normal;
  padding: ${Rem(20)};
`;

export const StyledProductType = styled.p`
  font-size: ${Rem(16)};
  font-weight: 200;
  margin-bottom: ${Rem(8)};
`;

export const StyledName = styled.div`
  font-size: ${Rem(24)};
  margin-bottom: ${Rem(12)};
`;

export const StyledPriceParagraph = styled.div`
  margin-bottom: ${Rem(12)};
  line-height: ${Rem(20)};
`;

export const StyledPriceValues = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const StyledRedRows = styled.div`
  color: #D11414;
  font-size: ${Rem(16)};
  display: inline-block;
  text-align: left;
  padding-left: ${Rem(10)};
`;

export const StyledRedRow = styled.div`
  font-weight: normal;
  font-size: ${Rem(18)};
`;

export const StyledFinalPrice = styled.span`
  white-space: nowrap;
  font-size: ${Rem(28)};
  color: ${Colors.yellow};
  margin-right: ${Rem(5)};
`;
export const StyledInitialPrice = styled.span`
  text-decoration: line-through;
  margin-right: ${Rem(5)};
`;

export const StyledDiscountPercentage = styled.span`
`;

export const StyledPriceSubtitle = styled.p`
  font-size: ${Rem(16)};
  font-weight: 200;
  margin-bottom: ${Rem(12)};
`;

export const StyledQuantityLeftContainer = styled.div`
  font-size: ${Rem(22)};
`;

export const StyledQuantityLeftText = styled.span`
  font-size: ${Rem(20)};
`;

export const StyledQuantityLeft = styled.span`
  font-size: ${Rem(20)};
`;

export const StyledShippingToContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: ${Rem(22)} 0 ${Rem(28)};
`;

export const StyledShippingTo = styled.span`
  font-size: ${Rem(18)};
`;

export const StyledFlag = styled.img`
  height: ${Rem(40)};
  margin-left: ${Rem(15)};
`;

export const StyledSaveCircle = styled.div`
  background: ${Colors.black};
  color: ${Colors.white};
  line-height: ${Rem(30)};
  font-weight: normal;
  width: ${Rem(100)};
  height: ${Rem(100)};
  position: absolute;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: ${(props) => Rem(props.circleTop)};
  right: ${(props) => Rem(props.circleRight)};
`;

export const StyledSaveCircleText = styled.div`
  display: none;
  font-weight: normal;
  font-size: ${Rem(18)};
  text-align: center;
`;

export const StyledFeatures = styled.div`
  display: inline-block;
  margin-left: ${Rem(4)};
`;

export const StyledFeaturesIcon = styled.div`
  font-weight: normal;
  font-size: ${Rem(18)};
  text-align: center;
  color: ${Colors.white};
  background-color: ${Colors.black};
  width: ${Rem(24)};
  height: ${Rem(24)};
  border-radius: 50%;
  cursor: pointer;
  line-height: ${Rem(26)};
  
  @media (${BP.tablet}) {
    line-height: ${Rem(28)};
  }
`;
export const StyledFeatureList = styled.div`
  color: black;
  padding: ${Rem(60)} ${Rem(10)};
  font-size: ${Rem(20)};

  h5.featureListTitle {
    margin-bottom: ${Rem(20)};
  }

  @media (${BP.tablet}) {
    padding: ${Rem(60)} 0;
    min-width: ${Rem(400)};
    font-size: ${Rem(30)};
  }
`;

export const StyledFeature = styled.div`
  span.label {
    color: ${Colors.yellow};
    display: block;
    margin-top: ${Rem(14)};

    @media (${BP.tablet}) {
      display: inline;
      margin-top: ${Rem(20)};
    }
  }
`;
